import React, { useEffect, useState, useRef } from "react"
import BannerHeader from "../components/bannerHeader"
import UnderlineButton from "../components/underlineButton"
import { Link } from "gatsby"

const PageNotFound = () => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(true)
  return (
    <div className={`page-not-found ${isNavBarOpen ? "" : "no-scroll"}`}>
      <div className="page-content">
        <div className="page-wrapper">
          <div className="page-not-found-container">
          <BannerHeader
            headingType={"bannerTitle"}
            header={"404"}
            line={"single-line"}
          />
          <h2 className="heading-sm">Ooops, page not found yet</h2>
          <p className="does-not-exits"> 
            The page you are looking for doesn’t exist or an another error
            occurred
          </p>
          <Link to='/' name="home">
            <UnderlineButton text="Go To Home Page" />

          </Link>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
